import { graphql } from 'gatsby';
import { EventLayout } from '../../components/entities/event-layout';
import { createGatsbyPageComponent } from '@shapeable/ui';

export default createGatsbyPageComponent('Event', { layout: EventLayout, debug: false });

export const QUERY = graphql`
  query eventQuery($id: ID!) {
    platform {
      event(id: $id) {
        id slug name path __typename _schema { label pluralLabel }
        title
        startDate
        endDate
        url
        overview { id text }
        isVirtual
        description { id text }
        type { id name slug __typename }
        images { image { id url url2x width height } }
        keyIssues { id name slug __typename }
        links { id url name label isDownload page { path } }
        location { id name country { id name } }
        video { id name url thumbnail { id url url2x } }
        banner {
          id name slug title description { text } alternateText
          image { 
            id url url2x width height 
          }
        }
        sessionInfo { id text }
        sessions { 
          id name __typename 
          startDate endDate
          icsPath(base: "/downloads")
          description { text }
          room { id name shortName }
          color { id veryLightValue lightValue value darkValue }
          presenters { 
            id name __typename
            bio { html }
            photo { url }
            position
            organisation { id name slug }
            openGraph { title description { plain } image { thumbnails { full { url url2x } } } }
          }
          group { id name shortName }
        }
      }
    }
  }`;

